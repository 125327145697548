import React from 'react'
import './style.css'

export default function Footer() {
  return (
    <div className='Footer'>
        Thank you for visiting
    </div>
  )
}
